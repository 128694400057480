.container {
  position: relative;
  padding-left: 8px;
  display: inline-block;
  cursor: pointer;

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.tooltip {
  position: absolute;
  z-index: 10;
  right: -200px;
  bottom: 30px;
  padding: 8px;
  visibility: hidden;
  width: 400px;
  font-weight: normal;
  line-height: 1.25;
  background-color: white;
  border: 1px solid whitesmoke;
  opacity: 0;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
}

.logo {
  margin-bottom: 8px;
  height: 130px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #ccc;
}

.logo_header {
  composes: logo;
  background-image: url('./images/logo_header.png');
}

.logo_mobile {
  composes: logo;
  background-image: url('./images/logo_mobile.png');
}

.logo_app {
  composes: logo;
  height: 350px;
  background-image: url('./images/logo_app.png');
}

.logo_big {
  composes: logo;
  background-image: url('./images/logo_big.png');
}

.logo_small_1 {
  composes: logo;
  background-image: url('./images/logo_small_1.png');
}

.logo_small_2 {
  composes: logo;
  background-image: url('./images/logo_small_2.png');
}

.pin_image {
  composes: logo;
  background-image: url('./images/pin_image.png');
}

.banner {
  composes: logo;
  background-image: url('./images/banner.png');
}

.example-text {
  margin-top: 8px;
}
