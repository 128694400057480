.head {
  display: flex;
  justify-content: space-between;
}

.head a {
  cursor: pointer;
}

.container.invalid {
  border-color: #a94442;
}
