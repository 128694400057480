.textarea {
  resize: vertical;
}

.textarea.invalid {
  border-color: #a94442;
}

.description-mobile {
  white-space: normal;
}
