/* stylelint-disable */

.container,
.input {
  all: initial;
  box-sizing: border-box;
  font-family: Lato, sans-serif;
  color: #121212;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; /* чтобы шрифт не увеличивался при повороте экрана на iOS */
  -moz-osx-font-smoothing: grayscale;

  &::before,
  &::after {
    all: initial;
    box-sizing: border-box;
    font-family: Lato, sans-serif;
    color: #121212;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%; /* чтобы шрифт не увеличивался при повороте экрана на iOS */
    -moz-osx-font-smoothing: grayscale;
  }
}

.container {
  position: relative;
  display: block;
}

.required::before {
  position: absolute;
  left: -8px;
  margin-top: 13px;
  width: 4px;
  height: 4px;
  content: '';
  background-color: #ff5050;
  border-radius: 50%;
}

.input {
  padding: 8px 8px 0;
  width: 100%;
  white-space: pre; /* https://bugzilla.mozilla.org/show_bug.cgi?id=1137650 */
  background-color: white;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  outline: none;
  transition: 0.15s border, 0.15s color;

  &::placeholder {
    color: #c9c9c9;
  }

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

.input:focus {
  border-color: #121212;
}

.text_length {
  position: absolute;
  right: 4px;
  bottom: 6px;
  padding: 2px 4px;
  display: block;
  font-size: 12px;
  color: #121212;
  background: white;
  border-radius: 4px;
  transition: 0.15s border, 0.15s right;
}

.text_length-scroll {
  right: 16px;
}

.invalid {
  border-color: #ff5050;
}

.input:disabled,
.text_length-disabled {
  color: color(#121212 a(50%));
}
