.input {
  position: absolute;
  z-index: -1;
  display: none;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}

.label {
  margin-bottom: 0;
  padding: 1px 5px;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  color: #333;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.label:hover,
.label:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.label.label-success {
  color: white;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.label.label-success:hover,
.label.label-success:active {
  color: white;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.preview {
  margin-top: 10px;
}

.preview-inline {
  margin-left: 10px;
  display: inline-block;
}
