.container {
  position: fixed;
  z-index: 100;
  top: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}

.alert {
  position: relative;
  padding: 20px;
  display: inline-block;
  width: 50%;
  border-radius: 5px;
}

.success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
