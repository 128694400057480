.color-picker {
  margin: 0 0 0 10px;
  padding: 0;
  display: inline-block;
  width: 60px;
  height: 34px;
  cursor: pointer;
  background: none;
  border: none;
}

.container {
  display: flex;
}

.container .invalid {
  border-color: #a94442;
}
