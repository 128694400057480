.multiselector-field .dropdown-btn {
  overflow: hidden;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
}

.multiselector-field .dropdown-btn .caret {
  position: absolute;
  top: 15px;
  right: 5px;
}

.list-item {
  margin-bottom: 5px;
  padding: 0 10px;
}

.search {
  padding: 0 10px 5px;
}

.multiselector-field .dropdown-menu {
  display: block;
  overflow: auto;
  width: 100%;
  max-height: 250px;
}

.dropdown-menu .label {
  margin-bottom: 0;
  padding: 5px 0;
  display: block;
  font-weight: normal;
  cursor: pointer;
}
