.container {
  position: relative;
  padding-top: 20px;
  padding-bottom: 40px;
}

.m-r-5 {
  margin-right: 5px;
}

.title {
  margin-right: 10px;
  display: inline-block;
}

.table {
  table-layout: fixed;
}
