.container .dropdown-btn {
  overflow: hidden;
  width: 100%;
  min-height: 34px;
  text-align: left;
  text-overflow: ellipsis;
  border: 1px solid #ced4da;
}

.container .dropdown-btn span {
  position: absolute;
  top: 15px;
  right: 5px;
}

.list-item {
  margin-bottom: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.active {
  color: white;
  background-color: #337ab7;
}

.search {
  padding: 0 10px 5px;
}

.container .dropdown-menu {
  display: block;
  overflow: auto;
  width: 100%;
  max-height: 250px;
}

.hint {
  margin-bottom: 1em;
  font-size: 12px;
  font-style: italic;
  color: grey;
}
